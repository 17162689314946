import React from "react"
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"

function DatePicker({ date, setDate }) {
  // Generiere futureDates für den Zeitraum vom 21. bis 30. September 2023
  const futureDates = []
  const startDate = new Date(2023, 8, 22)
  const endDate = new Date(2023, 10, 30)
  const currentDate = new Date(startDate)

  // Füge alle Tage vom Startdatum bis zum Enddatum zu futureDates hinzu
  while (currentDate <= endDate) {
    futureDates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  const handleDayClick = (day, modifiers) => {
    // if (modifiers.future) {    }
    setDate(day)
  }

  const modifiersStyles = {
    future: {
      //   fontWeight: "regular", // Fett hervorheben
      backgroundColor: "rgb(0, 0, 255, 0.15)", // Hintergrundfarbe beibehalten
      borderRadius: "0px"
    },
    selected: {
      color: "blue",
      backgroundColor: "white", // Hintergrundfarbe beibehalten
      fontWeight: "bold", // Fett hervorheben
    },
  }

  return (
    <DayPicker
      required
      modifiersStyles={modifiersStyles}
      modifiers={{ future: futureDates }}
      //   modifiersStyles={{ future: { backgroundColor: "lightblue" } }} // Beispielstil für zukünftige Termine
      mode="single"
      selected={date}
      defaultMonth={new Date(2023,8,10)}
      onDayClick={handleDayClick}
      fromDate={new Date(2023, 3, 1)}
      toDate={endDate}
    />
  )
}

export default DatePicker
