import React from "react"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"
import useTimeArray from "./hooks/useTimeArray"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

/*
  This component named 'Chart' renders a line chart using Chart.js and React-Chartjs-2 library.
  
  It utilizes a custom hook 'useTimeArray' to fetch time labels for the x-axis.
  
  The component receives 'predictions' and 'measurements' as props, which are arrays containing objects 
  with 'time' and 'visitors' properties representing predicted and measured visitor counts respectively.
  
  The chart is configured with various options including responsive layout, legend position, 
  y-axis scale limits, x-axis label formatting, and interaction settings.
  
  The data for the chart is structured using the provided 'predictions' and 'measurements' props.
  
  Two datasets are plotted on the chart: 'Vorhersage' (predictions) and 'Messung' (measurements).
  Each dataset consists of time and visitor count pairs.
  
  The component renders a div with a line chart inside, setting a fixed height of 50% viewport height.
*/

function Chart(props) {
  const { strings: labels } = useTimeArray()

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 20, // change this value to your desired legend font size
          },
        },
        position: "bottom",
      },
    },
    scales: {
      y: {
        max: 200,
        min: 0,
      },
      x: {
        ticks: {
          callback: function (val, index) {
            return parseInt(this.getLabelForValue(val).split(":")[0])
          },
          // autoSkip: true,
          maxTicksLimit: 18,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
  }

  const data = {
    labels,
    datasets: [
      {
        label: "Measurement",
        data: props?.measurements?.map((d) => [d.time, d.visitors]),
        borderColor: "blue",
        backgroundColor: "blue",
        borderWidth: 2,
      },
      {
        label: "Prediction",
        data: props?.predictions?.map((d) => [d.time, d.visitors]),
        borderColor: "rgb(0, 0, 255, 0.25)",
        backgroundColor: "rgb(0, 0, 255, 0.25)",
        borderDash: [5, 5],
        borderRadius: 100,
      },
    ],
  }
  return (
    <div style={{ height: "50vh" }}>
      <Line options={options} data={data} />
    </div>
  )
}

export default Chart
