import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import useDateString from "./useDateString"

/*
  This custom hook 'useMeasurements' fetches measurement data for a specific date from 
  
    ${process.env.REACT_APP_SERVER}/measurements/?date=${dateString} 

  The variable 'dateString' gets created by the 'useDateString' hook, based on the prop 'date'

  The fetched data is returned by the hook for use in components.
*/

const useMeasurements = (date) => {
  const [data, setData] = useState(null)
  const dateString = useDateString(date)

  const fetch = useCallback(async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/measurements/?date=${dateString}`)
      setData(res?.data)
    } catch (error) {
      console.error(error)
    }
  }, [dateString])

  useEffect(() => {
    fetch()
  }, [fetch])

  return data
}

export default useMeasurements
