import { useCallback, useState } from "react"
import * as tf from "@tensorflow/tfjs"
import useTimeArray from "./useTimeArray"

const model = await tf.loadLayersModel(
  `${process.env.REACT_APP_SERVER}/model.json`
)
/*
  This React hook called 'usePrediction' utilizes TensorFlow.js to load a 
  pre-trained neural network model for predicting visitor counts based on input arrays. 

  The 'usePrediction' hook returns two values: 
  - 'prediction': a state variable containing the predicted visitor counts
  - 'predict': a function that takes an input tensor, makes predictions using the loaded model, 
              and updates the 'prediction' state variable accordingly.

  Inside the 'predict' function, the input array is processed through the loaded model. 
  The predictions are then scaled back to the original visitor count range. 
  Finally, the predictions are mapped to an array of objects containing time labels and predicted visitor counts.

  This hook internally utilizes another custom hook called 'useTimeArray' 
  to fetch labels for the time intervals.

  Note: This code assumes the presence of certain environmental variables 
  such as 'REACT_APP_SERVER' to construct the model URL.
*/
const usePrediction = () => {
  const [prediction, setPrediction] = useState([])
  const { strings: timeStrings } = useTimeArray()

  const predict = useCallback(
    async (input) => {
      const outputMax = tf.tensor(240, [1, 1]).max()
      const outputMin = tf.tensor(0, [1, 1]).min()
      if (input.length === 0) return
      try {
        const predictions = await model
          .predict(input)
          .mul(outputMax.sub(outputMin))
          .add(outputMin)
          .array()

        setPrediction(
          predictions.map((arr, index) => {
            return { time: timeStrings[index], visitors: arr[0] }
          })
        )
      } catch (error) {
        console.error(error)
      }
    },
    [timeStrings]
  )

  return [prediction, predict]
}

export default usePrediction
