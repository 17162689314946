import { useEffect, useState } from "react"

/*
  This code defines a custom React hook called 'useTimeArray'.
  It generates time labels and corresponding float values for each minute from 06:00 to 23:59.

  The hook returns an object with two properties:
  - 'strings': an array of time labels in the format "HH:MM"
  - 'floats': an array of corresponding float values representing each time label in decimal format

  The 'useEffect' hook is used to initialize the 'strings' and 'floats' states 
  when the component mounts. These states are updated with time labels and float values 
  generated through nested loops iterating over hours and minutes.

  Each time label is padded with leading zeros if necessary to maintain a consistent format.

  This hook is designed to be used with the 'usePrediction' hook to provide time labels 
  for interpreting the predicted visitor counts.
*/
const useTimeArray = () => {
  const [strings, setStrings] = useState([])
  const [floats, setFloats] = useState([])

  useEffect(() => {
    let strings = []
    let floats = []

    for (let hour = 6; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute++) {
        floats.push(parseFloat(hour + minute / 60))
        strings.push(
          ("" + hour).padStart(2, 0) + ":" + ("" + minute).padStart(2, 0)
        )
      }
    }
    setStrings(strings)
    setFloats(floats)
  }, [])

  return {
    strings: strings,
    floats: floats,
  }
}

export default useTimeArray
