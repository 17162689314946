import { useCallback, useEffect, useState } from "react"
import * as tf from "@tensorflow/tfjs"
import useTimeArray from "./useTimeArray"
import useDateString from "./useDateString"

/*
  This code defines a custom React hook called 'useInputTensor'.
  It generates an input tensor for a neural network model based on the given date.

  The hook utilizes another custom hook called 'useTimeArray' to fetch float values 
  representing time intervals.

  The 'getInputArray' function generates an array representing input features for the neural network.
  It includes features such as time floats, holiday indicator, and binary representations of days and months.

  The 'useEffect' hook is used to compute the input tensor when the component mounts or when the 'date' prop changes.
  Inside the 'useEffect', the input features are normalized and transformed into a tensor.

  The input tensor is returned by the hook for use in the neural network model.

  Note: The holiday list and date formats are hardcoded for the year 2023.
*/
const useInputTensor = (date) => {
  const { floats } = useTimeArray()
  const dateString = useDateString(date)
  const [inputArray, setInputArray] = useState([])

  const getInputArray = useCallback(
    (date) => {
      const month = date.getMonth()
      const day = date.getDay()

      const holidays = [
        "2023-01-01",
        "2023-04-07",
        "2023-04-10",
        "2023-05-01",
        "2023-05-18",
        "2023-05-29",
        "2023-06-08",
        "2023-10-03",
        "2023-11-01",
        "2023-12-25",
        "2023-12-26",
      ]

      const holiday =
        day === 0 ||
        day === 6 ||
        holidays.find((element) => element === dateString) !== undefined
          ? 1
          : 0

      const days = [
        day === 1 ? 1 : 0,
        day === 2 ? 1 : 0,
        day === 3 ? 1 : 0,
        day === 4 ? 1 : 0,
        day === 5 ? 1 : 0,
        day === 6 ? 1 : 0,
        day === 0 ? 1 : 0,
      ]

      const months = [
        month === 0 ? 1 : 0,
        month === 1 ? 1 : 0,
        month === 2 ? 1 : 0,
        month === 3 ? 1 : 0,
        month === 4 ? 1 : 0,
        month === 5 ? 1 : 0,
        month === 6 ? 1 : 0,
        month === 7 ? 1 : 0,
        month === 8 ? 1 : 0,
        month === 9 ? 1 : 0,
        month === 10 ? 1 : 0,
        month === 11 ? 1 : 0,
      ]

      let inputArray = []
      floats.forEach((time) => {
        inputArray.push([time, holiday, ...days, ...months])
      })
      setInputArray(inputArray)
      return inputArray
    },
    [floats, dateString]
  )

  const [inputTensor, setInputTensor] = useState([])

  useEffect(() => {
    const inputMax = tf.tensor(24, [1, 1]).max()
    const inputMin = tf.tensor(0, [1, 1]).min()
    const inputArray = getInputArray(date)
    if (inputArray?.length === 0) return

    setInputTensor(
      tf
        .tensor(inputArray, [inputArray.length, inputArray[0].length])
        .sub(inputMin)
        .div(inputMax.sub(inputMin))
    )
  }, [getInputArray, date])

  return [inputTensor, inputArray]
}

export default useInputTensor
