/*
  This custom hook 'useDateString' returns a formatted date string based on the provided date object.

  If 'date' is a valid Date object, it extracts the year, month, and day components 
  to construct a formatted date string in the format "YYYY-MM-DD".

  The month and day components are padded with leading zeros if necessary.

  The hook is intended to be used for formatting date objects into strings in components or other hooks.
*/

const useDateString = (date) => {
  if (!date) return
  if (!(date instanceof Date)) return
  return (
    date.getFullYear() +
    "-" +
    ("" + (date.getMonth() + 1)).padStart(2, 0) +
    "-" +
    ("" + date.getDate()).padStart(2, 0)
  )
}

export default useDateString
