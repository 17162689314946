import React, { useEffect, useState } from "react"

import Chart from "./Chart"

import "./style.css"
import Container from "react-bootstrap/Container"

import generatedGitInfo from "./generatedGitInfo.json"
import useMeasurements from "./hooks/useMeasurements"
import usePrediction from "./hooks/usePrediction"
import useInputTensor from "./hooks/useInputTensor"
import DatePicker from "./DatePicker"

import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"

/*
  This 'App' component represents the main application layout.
  
  It imports and utilizes various components, hooks, and styles to render a dynamic web application.

  The component initializes state variables for 'date', which defaults to September 21, 2023.

  It utilizes custom hooks 'useMeasurements', 'useInputTensor', and 'usePrediction' to fetch data, 
  generate input tensors, and make predictions respectively based on the selected date.

  The component renders a header displaying the selected date, a line chart ('Chart') 
  displaying predictions and measurements, and a date picker ('DayPicker') 
  allowing users to select a new date within a specified range.

  Additionally, it displays version information fetched from 'generatedGitInfo.json'.

  Note: This code assumes the presence of certain environmental variables 
  such as 'REACT_APP_SERVER' to construct API URLs.
*/
function App() {
  const [date, setDate] = useState(new Date(2023, 8, 21))

  const measurements = useMeasurements(date)
  const [inputTensor] = useInputTensor(date)
  const [predictions, predict] = usePrediction(date)

  useEffect(() => {
    predict(inputTensor)
  }, [inputTensor, predict])

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <Card.Title>Selected Date: {date.toLocaleDateString()}</Card.Title>
          <Chart predictions={predictions} measurements={measurements} />
          {/* <div className="d-flex"> */}
          <Row md={2} className="pt-4">
            <div className="">
              <DatePicker date={date} setDate={setDate} />
            </div>
            <Alert variant="secondary">
              <p className="fw-bold">Gym Visitor Bump-Time Prediction</p>
              <p>
                This project aims to predict the number of visitors to a public
                gym using machine learning techniques.
              </p>
              <p>Information about the data:</p>
              <ul>
                <li>
                  Measurement Start: {new Date(2023, 3, 1).toDateString()}
                </li>
                <li>Measurement End: {new Date(2023, 8, 21).toDateString()}</li>
                <li>
                  Predictions based on TensorFlow.js linear regression model
                </li>
              </ul>
              <p>
                More information about the project:{" "}
                <a href="https://github.com/simonliebold/bump-times">
                  {generatedGitInfo.gitBranch +
                    "@" +
                    generatedGitInfo.gitCommitHash}
                </a>
              </p>
            </Alert>
          </Row>
          {/* </div> */}
        </Card.Body>
      </Card>
    </Container>
  )
}

export default App
